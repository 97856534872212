import { useCallback, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useDixa } from '@devsbb/dixa-sdk';

import { User } from 'ggApp/types/graphql';

import { getDixaHMAC } from './getDixaHMAC';

interface EnhancedProps {
    isLoggedIn: boolean;
    user: User;
}

const Dixa = ({ user, isLoggedIn }: EnhancedProps) => {
    const dixa = useDixa();
    const [token, setToken] = useState<string | undefined>();

    const getUserHmac = useCallback(async () => {
        if (!isLoggedIn || !user?.id) return;
        const response = await getDixaHMAC({ userId: user?.id });
        setToken(response);
    }, [user, isLoggedIn]);

    useEffectOnce(() => {
        dixa.load();

        return () => {
            dixa.shutdown();
        };
    });

    useEffect(() => {
        dixa.init({
            hideToggler: true,
        });
    }, [dixa]);

    useEffect(() => {
        getUserHmac();
    }, [user, isLoggedIn, getUserHmac]);

    useEffect(() => {
        if (isLoggedIn && token) {
            dixa.setUser({ type: 'verified', payload: { type: 'jwe', token } });
        }
    }, [isLoggedIn, token, dixa]);

    return null;
};

export default Dixa;
