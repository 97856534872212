import React from 'react';
import { getHIWPageUrl } from 'ggApp/utils/path';

import { Translator, PureTranslator } from 'ggApp/modules/translator';
import FacebookIcon from 'ggApp/shared/icons/SocialSharing/Facebook';
import InstagramIcon from 'ggApp/shared/icons/SocialSharing/Instagram';
import LinkedInIcon from 'ggApp/shared/icons/SocialSharing/LinkedIn';
import TwitterIcon from 'ggApp/shared/icons/SocialSharing/Twitter';
import YouTubeIcon from 'ggApp/shared/icons/SocialSharing/YouTube';
import SepaIcon from 'ggApp/shared/icons/Sepa';
import MasterCardIcon from 'ggApp/shared/icons/MasterCard';
import PayPalIcon from 'ggApp/shared/icons/PayPal';
import loadConfiguration from 'ggApp/utils/configuration';

// todo: change it on icons components (shared/icons)
import SslLock from './assets/lock.svg';
import VisaBadge from './assets/visa.svg';
import AmexBadge from './assets/amex.svg';

const { APP_CONFIG } = loadConfiguration();
const { shopHost } = APP_CONFIG;

const getFaqLink = (code, activeLanguage) => {
    if (code === 'business') {
        return activeLanguage === 'de'
            ? 'https://help.grover.com/de/collections/2171049-grover-business'
            : 'https://help.grover.com/en/collections/2171049-grover-business';
    }
    return activeLanguage === 'de'
        ? 'https://help.getgrover.com/#new-request'
        : 'https://help.grover.com/en/#new-request';
};

const copyrightText = {
    us: 'Tech, Inc. ',
    de: 'Deutschland GmbH',
    at: 'Österreich GmbH',
    nl: 'Nederland B.V.',
    es: 'Tech Rental Consumer Electronics Spain SL',
};

const getFooterInfo = (
    code,
    activeLanguage,
    businessStoreDealsEnabled,
    referAFriendFooterLinkEnabled = false,
    cardLinkOnWebFlagEnabled = false,
    registerFooterCtaClick,
    newReferralsHostEnabled = false,
) => ({
    social: [
        {
            icon: <FacebookIcon />,
            link: 'https://www.facebook.com/getgrover',
            internal: false,
        },
        {
            icon: <InstagramIcon />,
            link: 'https://www.instagram.com/getgrover/',
            internal: false,
        },
        {
            icon: <YouTubeIcon />,
            link: 'https://www.youtube.com/getgrover',
            internal: false,
        },
        {
            icon: <TwitterIcon />,
            link: 'https://twitter.com/getgrover',
            internal: false,
        },
        {
            icon: <LinkedInIcon />,
            link: 'https://www.linkedin.com/company/grover',
            internal: false,
        },
    ],
    contact: {
        buttonText: <Translator tk="CONTACT_BUTTON" />,
        text: <Translator tk="CONTACT_TEXT" />,
        internal: false,
    },
    company: {
        title: <Translator tk="COMPANY_LINKS_TITLE" />,
        links: [
            {
                text: <Translator tk="COMPANY_LINKS_FAQ_TEXT" />,
                link: getFaqLink(code, activeLanguage),
                internal: false,
            },
            {
                text: <Translator tk="COMPANY_LINKS_JOBS_TEXT" />,
                link: 'https://jobs.grover.com',
                internal: false,
            },
            {
                text: <Translator tk="COMPANY_LINKS_BLOG_TEXT" />,
                link: `https://www.grover.com/blog/${activeLanguage}`,
                internal: false,
            },
            {
                text: <Translator tk="COMPANY_LINKS_PRESS_TEXT" />,
                link: 'http://press.grover.com/',
                internal: false,
            },
        ],
    },
    offerings: {
        title: <Translator tk="OFFERINGS_LINKS_TITLE" />,
        links: [
            {
                text: <Translator tk="OFFERINGS_LINKS_TOP_PRODUCTS_TEXT" />,
                link: `/${code}-${activeLanguage}/trending`,
                internal: false,
            },
            (code !== 'business' || businessStoreDealsEnabled) && {
                text: <Translator tk="OFFERINGS_LINKS_DEALS_TEXT" />,
                link: `/${code}-${activeLanguage}/deals`,
                internal: true,
            },
            !['nl', 'es', 'us', 'at', 'de'].includes(code) && {
                text: <Translator tk="OFFERINGS_LINKS_BUSINESS_TEXT" />,
                link:
                    code === 'de'
                        ? `/business-${activeLanguage}`
                        : `/business_${code}-${activeLanguage}`,
                internal: false,
            },
            !['es', 'us'].includes(code) && {
                text: <Translator tk="OFFERINGS_LINKS_PARTNERS_TEXT" />,
                link: `/${code}-${activeLanguage}/g-explore/partners`,
                internal: false,
            },
            code !== 'us' &&
                referAFriendFooterLinkEnabled && {
                    text: <PureTranslator tk="ACCOUNT_MENU_REFERRALS_TITLE" />,
                    link: '/invite-friend',
                    internal: true,
                },
            cardLinkOnWebFlagEnabled &&
                ['de'].includes(code) && {
                    text: <PureTranslator tk="GROVER_CARD" />,
                    link: `/${code}-${activeLanguage}/card`,
                    internal: false,
                },
            !code.startsWith('business') &&
                newReferralsHostEnabled && {
                    text: <PureTranslator tk="PROFILE_LINKS_REFERRALS_TEXT" />,
                    link: `/referrals`,
                    internal: true,
                },
            !code.startsWith('business') && {
                text: <PureTranslator tk="OFFERINGS_LINKS_STUDENT_BEANS_TEXT" />,
                link: `https://grover-${code}.connect.studentbeans.com/${code}`,
                internal: false,
                onClick() {
                    registerFooterCtaClick({
                        eventLabel: 'Studentenrabatt',
                        eventCategory: 'offerings',
                    });
                },
            },
            code === 'us' && {
                text: <PureTranslator tk="OFFERINGS_LINKS_AFFILIATE_PROGRAM" />,
                link:
                    'https://www.daisycon.com/en/campaigns/16585-grover-us/?utm_source=campaign-signup&utm_medium=html-link&utm_campaign=16585&utm_content=Join%20the%20Grover%20Affiliate%20Program',
                internal: false,
            },
        ],
    },
    tips: {
        title: <Translator tk="TIPS_LINKS_TITLE" />,
        links: [
            {
                text: <Translator tk="TIPS_LINKS_HIW_TEXT" />,
                link: `${getHIWPageUrl(code, activeLanguage)}`,
                internal: false,
            },
            {
                text: <Translator tk="TIPS_LINKS_GREAT_CONDITION_TEXT" />,
                link: `/${code}-${activeLanguage}/g-about/great-condition-promise`,
                internal: false,
            },
            {
                text: <Translator tk="TIPS_LINKS_GROVER_CARE_TEXT" />,
                link: `/${code}-${activeLanguage}/g-about/grover-care`,
                internal: false,
            },
            {
                text: <Translator tk="TIPS_LINKS_LEGAL_TEXT" />,
                link: `/${code}-${activeLanguage}/g-about/legal`,
                internal: false,
            },
            {
                text: <Translator tk="TIPS_LINKS_CANCEL_SUBSCRIPTION" />,
                link: `${shopHost}/${code}-${activeLanguage}/request-cancellation`,
                internal: false,
            },
        ],
    },
    badges: [
        code !== 'us' && {
            key: 'paypal',
            alt: 'Paypal',
            children: <PayPalIcon />,
        },
        {
            key: 'mastercard',
            alt: 'Mastercard',
            children: <MasterCardIcon />,
        },
        {
            key: 'visa',
            alt: 'Visa',
            style: { backgroundImage: `url(${VisaBadge})` },
        },
        {
            key: 'amex',
            alt: 'Amex',
            style: { backgroundImage: `url(${AmexBadge})` },
        },
        code !== 'us' && {
            key: 'sepa',
            alt: 'Sepa',
            children: <SepaIcon />,
        },
        {
            key: 'ssl',
            alt: 'SSL Secure',
            style: { backgroundImage: `url(${SslLock})` },
        },
    ],
    legal: {
        links: [
            {
                text: <Translator tk="LEGAL_LINKS_PRIVACY_POLICY_TEXT" />,
                link: `/${code}-${activeLanguage}/g-about/${
                    code === 'us' || code === 'business_us'
                        ? 'privacy-policy'
                        : 'datenschutzerklaerung'
                }`,
                internal: false,
            },
            {
                text: <Translator tk="LEGAL_LINKS_TERMS_AND_CONDITIONS_TEXT" />,
                link: `/${code}-${activeLanguage}/g-about/${
                    code === 'us' || code === 'business_us' ? 'terms-and-conditions' : 'agb'
                }`,
                internal: false,
            },
            {
                text: <Translator tk="LEGAL_LINKS_IMPRINT_TEXT" />,
                link: `/${code}-${activeLanguage}/g-about/${
                    code === 'us' || code === 'business_us' ? 'imprint' : 'impressum'
                }`,
                internal: false,
            },
        ],
    },
    copyrightText: `Grover ${copyrightText[code] || `Group GmbH`} © ${new Date().getFullYear()}`,
});

export default getFooterInfo;
