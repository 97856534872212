import { getDixa } from '@devsbb/dixa-sdk';
import CookiesManager from 'ggApp/utils/CookiesManager';
import { fireEvent } from 'ggApp/modules/inwebview_api';
import { USER_LOGOUT } from 'ggApp/modules/auth/actionTypes';
import { isAppUser } from 'ggApp/routes/_wrap/MobileWrapper/utils';
import { POST_EVENTS } from 'ggApp/modules/inwebview_api/constants';
import { isNativeAuthEnabled } from 'ggApp/modules/inwebview_api/nativeAuthEvents';

const FOUR_HOURS_EXPIRATION_TIME_IN_SECONDS = 4 * 60 * 60;
const ONE_YEAR_EXPIRATION_TIME_IN_SECONDS = 365 * 24 * 60 * 60;
export const ONE_YEAR_EXPIRATION_TIME_IN_DAYS = 365;
export const THIRTY_DAYS_EXPIRATION_TIME_IN_DAYS = 30 * 24 * 60 * 60;

const COOKIE_PATH = '/';
const AUTH_TOKEN_COOKIE = 'AUTH_TOKEN_COOKIE';
const USER_ID_COOKIE = 'USER_ID_COOKIE';

function setCookieAuth({
    authToken,
    refreshToken = '',
    maxAge = FOUR_HOURS_EXPIRATION_TIME_IN_SECONDS,
    cookiesManager = new CookiesManager(),
    shouldNotifyNativeApp = true,
}) {
    cookiesManager.set('api_access_token', authToken, {
        maxAge,
    });
    cookiesManager.set('api_refresh_token', refreshToken, {
        maxAge: ONE_YEAR_EXPIRATION_TIME_IN_SECONDS,
    });

    if (shouldNotifyNativeApp) {
        fireEvent(AUTH_TOKEN_COOKIE, { authToken, refreshToken });
    }
}

export function saveAccessToken(authToken, maxAge) {
    setCookieAuth({ authToken, maxAge });
}

export function saveAuthCredentials(
    authToken,
    refreshToken,
    maxAge,
    cookiesManager,
    shouldNotifyNativeApp,
) {
    setCookieAuth({ authToken, refreshToken, maxAge, cookiesManager, shouldNotifyNativeApp });
}

export function saveUserIdCookie(userId, maxAge = ONE_YEAR_EXPIRATION_TIME_IN_SECONDS) {
    const cookiesManager = new CookiesManager();

    cookiesManager.set('user_id', userId, {
        maxAge,
    });

    fireEvent(USER_ID_COOKIE, { user_id: userId });
}

export async function forgetAuthCredentials(cookiesManager = new CookiesManager(), nonForceLogout) {
    const shouldClearCookies = !isAppUser() || (isAppUser() && nonForceLogout);
    const dixa = getDixa();

    // purge user's Dixa session
    dixa.endConversation();
    dixa.purgeUser();

    if (shouldClearCookies) {
        cookiesManager.remove('user_id', { path: COOKIE_PATH });
        cookiesManager.remove('api_access_token', { path: COOKIE_PATH });
        cookiesManager.remove('api_refresh_token', { path: COOKIE_PATH });
        cookiesManager.remove('user_type', { path: COOKIE_PATH });
    }

    if (nonForceLogout) {
        const nativeAuthActive = await isNativeAuthEnabled();
        if (nativeAuthActive) {
            fireEvent(POST_EVENTS.REQUEST_LOGOUT, { action: {} });
        } else {
            fireEvent(USER_LOGOUT, { action: {} });
        }
    }
}

export function cleanRecentSearchTermsStorage() {
    localStorage.removeItem('recentSearchTerms');
}

export function getAccessToken(cookiesManager = new CookiesManager()) {
    const accessToken = cookiesManager.get('api_access_token');
    if (!accessToken || accessToken === 'undefined') return null;
    return accessToken;
}

export function getRefreshToken(cookiesManager = new CookiesManager()) {
    return cookiesManager.get('api_refresh_token');
}

// this is our best guess as auth is not available in redux state during SSR
export function authenticatedBasedOnCookies(cookiesManager = new CookiesManager()) {
    const refreshToken = getRefreshToken(cookiesManager);
    const userId = cookiesManager.get('user_id');

    return Boolean(refreshToken) && Boolean(userId);
}
