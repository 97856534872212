import { datadogLogs } from '@datadog/browser-logs';
// Utils
import axios from 'ggApp/utils/requests';

export interface GetDixaHMACResponse {
    hmac: string;
}

export const getDixaHMACQueryName = 'getDixaHMAC';

interface GetDixaHMACOptions {
    userId?: string;
}

export const getDixaHMAC = async ({ userId }: GetDixaHMACOptions): Promise<string> => {
    if (!userId) {
        datadogLogs.logger.error(`API Error: getDixaHMAC (userId was not provided)`);
        throw new Error('Param userId was not provided');
    }

    try {
        datadogLogs.logger.info('Performed API call for getDixaHMAC');
        const response = await axios.get<GetDixaHMACResponse>(`/users/${userId}/dixa_verification`);
        datadogLogs.logger.info('Received API response for getDixaHMAC');

        return response.data.hmac;
    } catch (error) {
        datadogLogs.logger.error('API Error: getDixaHMAC', { error });
        throw error;
    }
};
