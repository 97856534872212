import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
    isLoggedIn as isLoggedInSelector,
    user as userSelector,
} from 'ggApp/modules/auth/selectors';
import { FC } from 'react';
import Dixa from './Dixa';

const enhance = compose(
    connect(
        createStructuredSelector({
            user: userSelector,
            isLoggedIn: isLoggedInSelector,
        }),
    ),
);

export default enhance(Dixa as FC);
