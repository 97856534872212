import { Header, HeaderConsumer, HeaderSearchResult } from '@devsbb/header-schema';
import { Theme } from '@getgrover/ui';
import { User } from '../../../types/graphql';
import { ActiveStore } from '../../../modules/store';

export type WithTransparentHeader = {
    withTransparentHeader?: boolean;
};

export enum DEVICE_TYPE {
    Mobile = 'mobile',
    Tablet = 'tablet',
    Desktop = 'desktop',
}

export type Breakpoints = {
    [key in DEVICE_TYPE]: Points;
};

type Points = {
    min: number;
    avg?: number;
    max?: number;
};

export interface GetHeaderQuery {
    header: Header;
}

export interface GetHeaderQueryVariables {
    ssr: boolean;
    consumer: HeaderConsumer;
}

export interface GetHeaderSearchQuery {
    search: HeaderSearchResult;
}

export interface GetHeaderSearchQueryVariables {
    q: string;
    consumer: HeaderConsumer;
}

export interface ModalProps {
    openModal: () => void;
}

export interface UserActions {
    logout: () => void;
}

export interface ActionProps {
    replace: (params: Record<string, unknown>) => void;
}

export interface HeaderV2EnhancedProps extends UserActions, ActionProps {
    user: User;
    userId?: number;
    store: ActiveStore;
    isLoggedIn: boolean;
    initDone?: boolean;
    locale: string;
    translationKeys: Record<string, unknown>;
    query: Record<string, unknown>;
    routes: Record<string, unknown>;
    params: Record<string, unknown>;
    sitewideBannerData: any;
}

export interface HeaderV2Props extends HeaderV2EnhancedProps {
    theme: Theme;
    ssrHeaderData?: GetHeaderQuery;
}
