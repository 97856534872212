import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HeaderConsumer, HeaderLink } from '@devsbb/header-schema';
import { useDixa } from '@devsbb/dixa-sdk';
import { useLazyQuery, useQuery } from '@apollo/client';
import { sendAnalyticsEvent } from '@devsbb/analytics-client';
import { DropDownOption } from '@getgrover/ui';
import { replace as replaceAction } from 'redux-router';
import { CATALOG_ROUTES_FOR_LIST } from 'ggApp/modules/gaAnalytics/constants';

import {
    DeviceTypes,
    HeaderSearchQueryDocument,
    HeaderQueryDocument,
    HeaderProvider,
} from '@devsbb/header-ui';
import { connect } from 'react-redux';
import ggAuth from 'ggApp/modules/auth';
import { keys } from 'ggApp/modules/translator/selectors';

import {
    user as userSelector,
    userId as userIdSelector,
    isLoggedIn as isLoggedInSelector,
} from 'ggApp/modules/auth/selectors';
import { currentLanguage as currentLanguageSelector } from 'ggApp/modules/store';
import { useTranslation } from 'react-i18next';
import { fireEvent } from 'ggApp/modules/inwebview_api';
import { storeActive as activeStoreSelector } from 'ggApp/modules/store/selectors';
import { AuthModal, FLOW_NAMES } from 'ggApp/modules/auth/v3';
import { locationQuery as querySelector } from 'ggApp/modules/router/selectors';
import {
    getStoreList,
    getLanguageList,
} from 'ggApp/shared/modals/CountryRedirection/DropDowns/utils';
import { defaultLanguageByCountry } from 'ggApp/modules/store/constants';

import loadConfiguration from 'ggApp/utils/configuration/configuration';
import { compose, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { datadogLogs } from '@datadog/browser-logs';
import { getUrlPrefix } from 'ggApp/utils/storeLangInjection';
import { setLocaleCookie } from 'ggApp/modules/store/utils';
import { POST_EVENTS } from 'ggApp/modules/inwebview_api/constants';
import debounce from 'lodash/debounce';
import { useSitewideBanner } from 'ggApp/shared/hooks/useSitewideBanner';
import { initDone as initDoneSelector } from 'ggApp/modules/init/selectors';
import { sitewideBannerFlagData } from 'ggApp/modules/featureFlag';
import { getOrder as getOrderFromCookies } from 'ggApp/modules/order/storage';
import B2CRedirection from '../../modals/B2CRedirection';
import {
    DEVICE_TYPE,
    GetHeaderSearchQuery,
    GetHeaderSearchQueryVariables,
    HeaderV2EnhancedProps,
    HeaderV2Props,
    UserActions,
} from './types';
import { useDeviceInfo } from './useDeviceInfo';
import CartButton from '../CartButton';
import { HeaderWrapper } from './styles';

const Header = ({
    store,
    user,
    userId,
    isLoggedIn,
    initDone,
    locale,
    logout,
    replace,
    sitewideBannerData,
    translationKeys,
}: HeaderV2Props): JSX.Element => {
    const [searchQuery, setSearchQuery] = useState('');
    const { t } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState('');

    const [authFlow, setAuthFlow] = useState(FLOW_NAMES.SIGN_UP_CUSTOMER);
    const authModalRef = useRef<{ openModal: () => void } | null>(null);
    const { APP_CONFIG } = loadConfiguration();
    const { shopHost: baseUrl } = APP_CONFIG;
    const homePageRegex = new RegExp(`/(${store.code}-${locale})/?$`);

    const createModalRef = useCallback((authModal) => {
        authModalRef.current = authModal;
    }, []);

    const { deviceType } = useDeviceInfo();

    const [businessRedirectModal, setBusinessRedirectModal] = useState<any | null>();
    const createModalRefBusinessRedirect = useCallback((modalBusiness: any) => {
        setBusinessRedirectModal(modalBusiness);
    }, []);

    const isBusinessStore = store.code?.includes('business');

    const dixa = useDixa();

    const { showBanner, bannerText, handleBannerClose } = useSitewideBanner(
        sitewideBannerData,
        store,
        userId,
        initDone,
    );

    const { orderNumber, orderToken } = getOrderFromCookies(store.code);

    const { loading: headerLoading, data: headerData } = useQuery(HeaderQueryDocument, {
        variables: { ssr: false, consumer: HeaderConsumer.catalog, orderNumber, orderToken },
        onError: (error) => {
            datadogLogs.logger.error('Fetching Header Query in Frontshop failed', {
                error,
            });
        },
    });
    const [getHeaderSearch, { loading: searchLoading, data: searchData }] = useLazyQuery<
        GetHeaderSearchQuery,
        GetHeaderSearchQueryVariables
    >(HeaderSearchQueryDocument);

    const handleNavigate = ({ url }: { url: string }) => {
        const foundRoute = Object.values(CATALOG_ROUTES_FOR_LIST).find((route) =>
            url.includes(route),
        );

        if (homePageRegex.test(url) || foundRoute) {
            window.location.href = url;
            return;
        }

        replace({
            pathname: url,
        });
    };

    const debouncedGetHeaderSearch = useMemo(
        () =>
            debounce((query: string) => {
                getHeaderSearch({
                    variables: {
                        q: query,
                        consumer: HeaderConsumer.catalog,
                    },
                });
            }, 800),
        [getHeaderSearch],
    );

    useEffect(() => {
        const trimmedQuery = searchQuery.trim();
        if (trimmedQuery) {
            debouncedGetHeaderSearch(trimmedQuery);
        }
    }, [debouncedGetHeaderSearch, searchQuery]);

    const setFlow = (flow: string) => {
        if (!authModalRef.current) {
            return;
        }

        setAuthFlow(flow);
        authModalRef.current.openModal();
    };

    const handleLogout = () => {
        logout();
    };

    const handleLoginClick = async () => {
        setFlow(FLOW_NAMES.SIGN_IN);
    };

    const handleSignupClick = () => {
        if (isBusinessStore) {
            window.location.assign(
                `${baseUrl}/${store.code}-${store.default_language}/auth?flow=SIGN_UP_BUSINESS`,
            );
        } else {
            setFlow(FLOW_NAMES.SIGN_UP_CUSTOMER);
        }
    };

    const getLanguages = (selectedLanguageValue: string) => {
        return getLanguageList({
            selectedStoreValue: store.code,
            selectedLanguageValue,
            translationKeys,
            activeLanguage: store.activeLanguage,
        });
    };
    const onChangeStore = (value: DropDownOption['value']) => {
        const stores = getStoreList({
            selectedStoreValue: value,
            translationKeys,
            activeLanguage: store.activeLanguage,
        });
        if (value !== store.code) {
            fireEvent(POST_EVENTS.STORE_CHANGED, {});

            const languages = getLanguages(selectedLanguage);
            const storeHasLanguage = languages.some(
                (option: { value: string }) => option.value === selectedLanguage,
            );
            if (storeHasLanguage) {
                const updatedPath = getUrlPrefix({
                    storeCode: stores[0].value,
                    language: languages[0].value,
                });
                window.location.href = updatedPath;
            } else {
                if (typeof value !== 'string') return;
                let countryCode = value.toUpperCase();

                if (isBusinessStore) {
                    countryCode = value === 'business' ? 'DE' : value.split('_')[1].toUpperCase();
                }
                const defaultLanguage = defaultLanguageByCountry[countryCode];
                const updatedPath = getUrlPrefix({ storeCode: value, language: defaultLanguage });
                window.location.href = updatedPath;
            }
            setLocaleCookie(selectedLanguage);
        }
    };

    const onChangeLocale = (value: DropDownOption['value']) => {
        if (value !== store.activeLanguage) {
            fireEvent(POST_EVENTS.SET_ACTIVE_STORE_LANGUAGE, {
                action: { language: value },
            });

            setLocaleCookie(value);
            if (window) {
                const updatedPath = getUrlPrefix({
                    storeCode: store.code,
                    language: value,
                });
                setSelectedLanguage(value.toString());
                window.location.href = updatedPath;
            }
        }
    };

    const handleOfferingsOnClick = (event: any, link: HeaderLink): void => {
        if (
            link.text?.includes(t('OFFERINGS_LINKS_B2C_TEXT')) &&
            isLoggedIn &&
            (!user || store.code?.includes('business'))
        ) {
            event.preventDefault();
            businessRedirectModal?.openModal();
        }
    };

    const handleSupportClick = dixa.show;

    const enabledSearchLoading = !!searchQuery && searchLoading;

    const isMobile = deviceType !== DEVICE_TYPE.Desktop;
    const hideHalfHeader = isMobile && !window.location.pathname.includes('your-profile');

    const banner = showBanner
        ? {
              onClose: handleBannerClose,
              text: bannerText,
          }
        : undefined;

    return (
        <HeaderProvider>
            <HeaderWrapper
                banner={banner}
                showHalfHeaderOnScroll={hideHalfHeader}
                header={{
                    data: headerData?.header,
                    loading: headerLoading,
                }}
                search={{
                    data: searchData?.search,
                    loading: enabledSearchLoading,
                }}
                query={searchQuery}
                onQueryChange={setSearchQuery}
                consumer={HeaderConsumer.catalog}
                onNavigate={handleNavigate}
                cart={<CartButton hideButtonStyling />}
                deviceType={(deviceType as unknown) as DeviceTypes}
                onLogin={handleLoginClick}
                onLogout={handleLogout}
                onSignup={handleSignupClick}
                onStoreChange={onChangeStore}
                onLocaleChange={onChangeLocale}
                onLinkClick={handleOfferingsOnClick}
                unreadMessages={0}
                sendAnalyticsFunctionFromConsumer={sendAnalyticsEvent}
                onSupportClick={handleSupportClick}
            />
            {isBusinessStore && <B2CRedirection modalRef={createModalRefBusinessRedirect} />}
            <AuthModal modalRef={createModalRef} flow={authFlow} />
        </HeaderProvider>
    );
};

const enhance = compose<HeaderV2Props, HeaderV2EnhancedProps>(
    connect<HeaderV2EnhancedProps, {}, HeaderV2Props>(
        createStructuredSelector({
            user: userSelector,
            userId: userIdSelector,
            store: activeStoreSelector,
            isLoggedIn: isLoggedInSelector,
            initDone: initDoneSelector,
            query: querySelector,
            translationKeys: keys,
            locale: currentLanguageSelector,
            sitewideBannerData: sitewideBannerFlagData,
        }),
        (dispatch) => ({
            userActions: bindActionCreators(ggAuth.actions, dispatch),
            replace: bindActionCreators(replaceAction, dispatch),
        }),
    ),
    withHandlers<
        {
            logout: () => void;
            userActions: UserActions;
        },
        {}
    >({
        logout: ({ userActions }: { userActions: UserActions }) => () => {
            userActions.logout();
        },
    }),
);

export default enhance(Header);
